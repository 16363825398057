import UniMessage from './uniMessage';

export default class Storage {
    static async  setItem (key,value){
        const env = await getEnv();
        if(env.h5 === true){
            await window.localStorage.setItem(key, value);
        }else{
            UniMessage.send('StorageSetItem',{key,value});
        }
    }

    static async getItem (key){
        const env = await getEnv();
        if(env.h5 === true){
            return await window.localStorage.getItem(key);
        }else{
            return new Promise(resolve => {
                const obs = {
                    // nvue通过 webview.evalJS调用UniMessage.on方法会触发此回调
                    onUniMessage (event,data){
                        if(data.errMsg === 'getStorage:ok'){
                            resolve(data.data);
                        }else{
                            resolve(undefined);
                        }
                        UniMessage.remove(obs);
                    }
                };
                UniMessage.reg(obs);
                // 发送StorageGetItem消息给nvue
                UniMessage.send('StorageGetItem',{key});
            });



        }
    }
}


export async function getEnv (){
    return new Promise(resolve => {
        window.webUni.getEnv(function (res) {
            resolve(res);
        });
    });
}

export async function uniRedirect(url){
    window.webUni.navigateTo({
        url,
    });
}
