import {request} from './request';
import  {baseURL} from './config';
// 上传blob格式
export async function uploadBlob (uuid,blob){
    const file = new window.File([blob], uuid);
    return uploadFile(uuid,file);
}
// 上传文件格式
export async function uploadFile (filename,file){
    const data = new FormData;
    data.append(filename,file);
    return request({
        method:'POST',
        url:'files',
        data,
        headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
    });
}
// 获取文件
export async function getFile (filename){
    return request({
        method:'GET',
        url:'files/'+filename
    });
}

export async function getDownloadUrl (filename){
    return baseURL+ 'files/' + filename;
}
