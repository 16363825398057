import {gn, onTouchEndBind} from '../utils/lib';
import Localization from '../utils/Localization';
import OS from '../tablet/OS';
import Lobby from '../lobby/Lobby';

export function homeMain () {
    onTouchEndBind(gn('logotab'),homeGoBack);
    homeStrings();
    OS.getsettings(doNext);
    function doNext (str) {
        var list = str.split(',');
        OS.path = list[1] == '0' ? list[0] + '/' : undefined;
        Lobby.appinit(window.Settings.scratchJrVersion);
    }

    if (window.Settings.homeBack != 1){
        gn('logotab').style.visibility = 'hidden';
    }
}

function homeGoBack () {
    if (window.Settings.homeBack == 1){
        OS.analyticsEvent('Jump', 'AppActivity');
    }
}

function homeStrings () {
    gn('interfacetab-text').textContent = Localization.localize('INTERFACE_GUIDE');
    gn('painttab-text').textContent = Localization.localize('PAINT_EDITOR_GUIDE');
    gn('blockstab-text').textContent = Localization.localize('BLOCKS_GUIDE');
}
