const obsList = [];
let message = '';
export default class UniMessage {
    static set message (msg){
        message = msg;
    }
    static get message (){
        return message;
    }
    static send (event,data){
        window.webUni.postMessage(
            {
                data:{
                    event,
                    data
                }
            }
        );
    }

    static on (event,message){
        obsList.forEach(obs=>{
            if(typeof obs.onUniMessage === 'function'){
                obs.onUniMessage(event,message);
            }
        });
    }

    // 注册接收者
    static reg (obs){
        obsList.push(obs);
    }

    // 删除接收者
    static remove (obs){
        for(let i=0; i<obsList.length;i++){
            if(obsList[i] === obs){
                obsList.splice(i,1);
            }
        }
    }
}

window.UniMessage = UniMessage;
